.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-size: cover;
  background-position: center;
  position: relative;
}

.overlay {
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0.5);
}

img{
  height: 100px;
  width: 300px;
  align-items: center;
}
.content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 2rem;
  background: rgba(33, 37, 41, 0.8);
  border-radius: 1rem;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.2);
  color: white;
}

.title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.description {
  font-size: 1.125rem;
}

.coming-soon {
  margin-top: 1.5rem;
  font-size: 1.25rem;
  font-weight: 600;
}
